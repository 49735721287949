import React from 'react';
import { graphql } from 'gatsby';

import { PageMeta } from '@components/pageMeta';
import Layout from '@components/layout';
import ModuleZone from '~moduleZone';

const ModularPage = ({ data: { page } }) => {
  const { pageModules, config, seo } = page || {};

  const pageNav = pageModules?.modules?.find(
    (module) => module?._type === 'pageNav'
  );

  return (
    <Layout {...config} keepNav={pageNav?.keepNav}>
      <PageMeta {...seo} />
      {pageModules && <ModuleZone {...pageModules} />}
    </Layout>
  );
};

export default ModularPage;

export const pageQuery = graphql`
  query modularPageQuery($slug: String!) {
    page: sanityModularPage(slug: { current: { eq: $slug } }) {
      title
      seo {
        ...SEO
      }
      config {
        ...PageSettings
      }
      pageModules: modules {
        ...PageModules
      }
    }
  }
`;
